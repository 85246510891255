import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { PUBLIC_RESERVATIONS_REHABILITATION_SOBIESKI, PUBLIC_RESERVATIONS_REHABILITATION_ONLINE } from 'Consts/routes';

import { withVariables } from 'Utils/string';
import { parseQueryToObject } from 'Utils/querystring';

import Head from 'Components/core/Head';
import StyledComponent from 'Components/core/StyledComponent';
import LayoutContainer from 'Components/layout/LayoutContainer';
import Button from 'Components/layout/Button';

export const LOCATION_TYPE_OFFLINE = 'offline';
export const LOCATION_TYPE_ONLINE = 'online';

export default class PublicReservationRehabLP extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };
    static defaultProps =  {};

    state = {
        locationType: null,
    }

    render() {
        const { history, location } = this.props;
        const { locationType } = this.state;
        const { search } = location;

        const queryObject = parseQueryToObject(search, true);

        return (
            <StyledComponent
                className="component-reservation-rehab-lp"
                styles={require('./styles')}
            >
                <Head
                    title="Rezerwacje treningów"
                    description="Rezerwacje treningów FitAdept"
                />
                <LayoutContainer>
                    <div className="body">
                        <div className="section introduction">
                            <h1 className="headline">
                                Dziękujemy za wypełnienie ankiety
                            </h1>
                            <h3 className="subheadline">
                                Na podstawie wypełnionej przez Ciebie ankiety dobierzemy teraz odpowiedniego trenera diagnostę, który przeprowadzi trening diagnostyczny.
                                <br />
                                FitAdept to największa w Polsce społeczność certyfikowanych trenerów personalnych, fizjoterapeutów i pasjonatów sportu.
                            </h3>
                        </div>
                        <div className="section selector">
                            {!locationType && (
                                <div className="selector-step selector-step-location">
                                    <div className="location-type">
                                        <p className="location-type-info">
                                            Powiedz nam jak chcesz trenować?
                                        </p>
                                        <div className="buttons">
                                            <a 
                                                className={classnames({
                                                    active: locationType === LOCATION_TYPE_OFFLINE,
                                                    button: true,
                                                })} 
                                                onClick={() => this.setState({ locationType: LOCATION_TYPE_OFFLINE })}
                                            >
                                                1. Chcę trenować w studiu treningowym w centrum Warszawy (Plac Zawiszy 1)
                                            </a>
                                            <a
                                                className={classnames({
                                                    active: locationType === LOCATION_TYPE_ONLINE,
                                                    button: true,
                                                })}
                                                onClick={() => this.setState({ locationType: LOCATION_TYPE_ONLINE })}
                                            >
                                                2. Chcę trenować online
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {locationType && (
                                <div className="selector-step selector-step-checkout">
                                    <div className="location-header">
                                        <h3 className="location-headline">
                                            {locationType === LOCATION_TYPE_ONLINE 
                                                ? 'Wybrałeś treningi online'
                                                : 'Wybrałeś treningi w studiu w centrum Warszawy (Plac Zawiszy 1)'
                                            }
                                        </h3>
                                        <a 
                                            className="location-change"
                                            onClick={() => this.setState({ locationType: null })}
                                        >
                                            Zmień lokalizację
                                        </a>
                                    </div>
                                    <div className="info-boxes-container">
                                        <div className="box">
                                            <div className="icon-container">
                                                <FontAwesomeIcon icon={['fa', 'running']} />
                                            </div>
                                            <p className="title">
                                                {locationType === LOCATION_TYPE_ONLINE 
                                                    ? '60-minutowa indywidualna lekcja i ćwiczenia 1:1 za pośrednictwem kamerki w telefonie, komputerze, smart-TV' 
                                                    : '60-minutowa indywidualna lekcja i ćwiczenia w kameralnym studiu treningowym FitAdept w centrum Warszawy (pl. Zawiszy)'
                                                }
                                            </p>
                                        </div>
                                        <div className="box">
                                            <div className="icon-container">
                                                <FontAwesomeIcon icon={['fa', 'handshake']} />
                                            </div>
                                            <p className="title">
                                                Niezobowiązująca formuła, chcemy najpierw lepiej się poznać aby sprawdzić czy możemy Ci pomóc
                                            </p>
                                        </div>
                                        <div className="box">
                                            <div className="icon-container">
                                                <FontAwesomeIcon icon={['fa', 'dollar-sign']} />
                                            </div>
                                            <p className="title">W 100% bezpłatny</p>
                                        </div>
                                    </div>
                                    <div className="section-footer">
                                        <Button 
                                            size="large"
                                            onClick={() => locationType === LOCATION_TYPE_OFFLINE
                                                ? history.push(withVariables(PUBLIC_RESERVATIONS_REHABILITATION_SOBIESKI.path, {}, { code: queryObject.code }))
                                                : history.push(withVariables(PUBLIC_RESERVATIONS_REHABILITATION_ONLINE.path, {}, { code: queryObject.code }))}
                                        >
                                            Odbierz bezpłatny trening diagnostyczny
                                        </Button>
                                        <h3 className="subheadline">Oferta limitowana</h3>
                                        <div className="specific-information">
                                            <p className="specific-information-text">
                                            Trening diagnostyczny to badanie Twojej dotychczasowej aktywności fizycznej, żywienia i zdrowia, na którym wykonujemy testy sprawnościowe i pokazujemy jak wyglądają z nami treningi.
                                            Trener pomoże Ci wyznaczyć realne cele treningowe, określi ramy czasowe ich realizacji, częstotliwość treningów oraz zarysuje plan ćwiczeń.
                                            W efekcie treningu otrzymasz w pełni spersonalizowany raport i wskazówki dalszych działań (recepta dalszych ćwiczeń i działań).
                                            </p>
                                        </div>
                                    </div>
                                   
                                </div>
                            )}
                        </div>
                    </div>
                </LayoutContainer>
            </StyledComponent>
        );
    }
}
