import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    min-height: 60vh;
    position: relative;
    background-color: ${variables.dpc_backgroundWhite};

    .body {
        margin-top: 2em;

        .section {
            text-align: left;
            color: ${variables.dpc_fontDark};
            
            &.introduction {    
                margin-bottom: 4em;

                .headline {
                    font-size: 3em;
                    font-weight: 800;
                    line-height: 110%;
                }

                .subheadline {
                    margin-top: .5em;
                    font-size: 1.2em;
                    font-weight: 300;
                }
            }

            &.selector {
                .selector-step {
                    &.selector-step-location {
                        .location-type {
                            .location-type-info {
                                font-size: 2em;
                                font-weight: 300;
                            }

                            .buttons {
                                margin-top: 1em;

                                .button {
                                    display: block;
                                    cursor: pointer;
                                    margin: .5em 0;
                                    padding: 1em;
                                    text-align: left;
                                    font-size: 1.4em;
                                    font-weight: 600 !important;
                                    color: ${variables.dpc_fontDark};
                                    font-weight: 300;
                                    border-bottom: .1em solid #333333;

                                    &:hover {
                                        border-color: #FF0031;
                                        background-color: ${variables.dpc_backgroundLightRed};
                                    }
                                }
                            }
                        }
                    }

                    &.selector-step-checkout {
                        .location-header {
                            margin-bottom: 1em;
                            width: 100%;
                            border-bottom: .1em solid #333333;
                            padding-bottom: 1em;

                            .location-headline {
                                font-size: 2em;
                                font-weight: 300;
                            }

                            .location-change {
                                color: #FF0031;
                                font-weight: 600;
                                font-size: .9em;
                                margin-top: .5em;
                                display: block;
                                cursor: pointer;
                            }
                        }
                        .info-boxes-container {
                            display: flex;
                            justify-content: space-between;
                            align-items: stretch;
                            width: 100%;

                            .box {
                                width: 100%;
                                flex-direction: column;
                                justify-content: flex-start;
                                align-items: center;
                                padding: 1em;

                                .title {
                                    text-align: center;
                                    margin-top: 1em;
                                    color: ${variables.dpc_fontDark};
                                    font-weight: 300;
                                    font-size: 1em;
                                }
                                
                                .icon-container {
                                    width: auto;
                                    height: 3em;
                                    color: ${variables.dpc_fontDark};

                                    svg {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
                            }
                        }

                        .section-footer {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                            margin-top: 1em;
                            width: 100%;
                            border-top: .1em solid #333333;
                            padding-top: 2em;

                            .subheadline {
                                margin-top: .3em;
                                font-size: 1em;
                                font-weight: 600;
                                color: ${variables.dpc_fontDark};
                            }

                            .specific-information {
                                margin-top: 2em;
                                text-align: center;

                                .specific-information-text {
                                    font-size: .9em;
                                    color: #666666;
                                    font-weight: 300;                                    
                                }
                            }
                        }
                    }
                } 
            }
        }
    }

    @media (max-width: ${variables.tabletL}) {}
    @media (max-width: ${variables.mobileL}) {
        .body {
            margin-top: 1em;
            font-size: 80%;

            .section {                
                &.introduction {    
                    .headline {}
                    .subheadline {}
                }

                &.selector {
                    .selector-step {
                        &.selector-step-location {
                            .location-type {
                                .location-type-info {}

                                .buttons {
                                    .button {}
                                }
                            }
                        }

                        &.selector-step-checkout {
                            .location-header {
                                .location-headline {}
                                .location-change {}
                            }
                            .info-boxes-container {
                                flex-direction: column;

                                .box {
                                    margin-bottom: 1em;

                                    .title {}
                                    .icon-container {}
                                }
                            }

                            .section-footer {
                                .subheadline {}

                                .specific-information {
                                    .specific-information-text {}
                                }
                            }
                        }
                    } 
                }
            }
        }
    }
`;

